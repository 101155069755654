import Link from "next/link";
import { useState } from "react";
import dynamic from "next/dynamic";

// import CLLogo from '../assets/charmelle-london-logo-2.svg'
// import Calendar from '../assets/calendar.svg'
// import Location from '../assets/location.svg'
// import Phone from '../assets/phone.svg'
import { Conversion, recordConversion } from "../utils/conversions";
import { trackAction } from "../utils/analytics";
import { format } from "date-fns";

const CLLogo = dynamic(() => import("../assets/logo.svg"));
const Calendar = dynamic(() => import("../assets/calendar.svg"));
const Location = dynamic(() => import("../assets/location.svg"));
const Phone = dynamic(() => import("../assets/phone.svg"));

export const legal = [
  {
    text: "Terms and Conditions",
    href: "/terms-and-conditions/",
  },
  {
    text: "Cancellation Policy",
    href: "/cancellation-policy/",
  },
  {
    text: "COVID-19 Policy",
    href: "/coronavirus-policy/",
  },
  {
    text: "Contact",
    href: "/contact/",
  },
];

export const about = [
  {
    text: "About Us",
    href: "/about/",
  },
  // {
  //   text: 'Location',
  //   href: '/how-to-get-here/',
  // },
  {
    text: "Careers",
    href: "/careers/",
  },
  {
    text: "Refer A Friend",
    href: "/referrals/",
  },
  {
    text: "Klarna FAQs",
    href: "/pay-later/",
  },
];

const services = [
  {
    text: "Promotions",
    href: "/promotions/",
  },
  {
    text: "Dermal Fillers",
    href: "/dermal-fillers/",
  },
  {
    text: "Facials",
    href: "/treatments/face/",
  },
  {
    text: "Laser Hair Removal",
    href: "/laser-hair-removal/",
  },
  // {
  //   text: "Massage",
  //   href: "/treatments/body/",
  // },
  // {
  //   text: "Semi Permanent Makeup",
  //   href: "/permanent-makeup/",
  // },
];

const services2 = [
  {
    text: "Fat Freezing / Dissolving",
    href: "/treatments/weight-loss/",
  },
  {
    text: "Weight Loss",
    href: "/treatments/weight-loss/",
  },
  {
    text: "Skin Boosters",
    href: "/skin-boosters/",
  },
  // {
  //   text: "Facials",
  //   href: "/treatments/face/",
  // },
  // {
  //   text: 'SkinCeuticals Products',
  //   href: '/products/',
  // },
];

export default function Footer() {
  const [subscribed, setSub] = useState(false);

  const capture = async (e) => {
    e.preventDefault();
    trackAction("newsletter");
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);
    try {
      fetch("/api/subscribe/", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      setSub(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <footer
      className="bg-white md:mb-12 shadow-inner"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Soluna London
      </h2>
      <div className="flex flex-col max-w-7xl mx-auto pt-12 sm:px-6 lg:pt-16 lg:px-8 md:flex-row">
        <div className="flex-1 mb-8 md:mb-0">
          <div className="w-full text-center px-14 md:w-44 md:px-0">
            {/* @ts-ignore */}
            <CLLogo className="w-full" height={140} />
          </div>
        </div>
        <div className="px-6 md:px-0 justify-between items-center flex-col flex space-y-4 lg:flex-row lg:space-y-0 lg:w-1/2">
          <div className="w-full">
            <Link
              href="/consultations/"
              passHref
              className="btn-primary"
              id="footer-consultation-button"
            >
              <div className="flex w-full justify-center items-center">
                <div className="mr-4">
                  <Calendar
                    // @ts-ignore
                    width={24}
                    height={24}
                    className="fill-current"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-[17px]">Book Free Consultation</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="w-full lg:ml-6">
            <a
              onClick={() => trackAction("phone")}
              href="tel:+443330163500"
              className="btn-primary"
              id="call-now-button-footer"
            >
              <div className="flex w-full justify-center items-center">
                <div className="mr-2">
                  <Phone
                    // @ts-ignore
                    width={24}
                    height={24}
                    className="fill-current"
                  />
                </div>
                <div className="flex flex-col">
                  <span className="phone-number text-[17px]">
                    0333 016 3500
                  </span>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto pb-12 pt-12 px-4 sm:px-6 lg:pt-12 lg:pb-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                  Services
                </h3>
                <ul className="mt-4 space-y-4">
                  {services.map((link, idx) => (
                    <li key={`services-${idx}`}>
                      <Link
                        href={link.href}
                        passHref
                        className={`text-base ${
                          link.text === "Promotions"
                            ? "text-brand-600"
                            : "text-gray-600"
                        } hover:text-gray-900`}
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-9 space-y-4">
                  {services2.map((link, idx) => (
                    <li key={`services2-${idx}`}>
                      <Link
                        href={link.href}
                        passHref
                        className="text-base text-gray-600 hover:text-gray-900"
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {about.map((link, idx) => (
                    <li key={`about-${idx}`}>
                      <Link
                        href={link.href}
                        passHref
                        className="text-base text-gray-600 hover:text-gray-900"
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link
                      href="/price-list/"
                      passHref
                      className="text-base text-gray-600 hover:text-gray-900"
                    >
                      Price list
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/articles/"
                      passHref
                      className="text-base text-gray-600 hover:text-gray-900"
                    >
                      Articles
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {legal.map((link, idx) => (
                    <li key={`legal-${idx}`}>
                      <Link
                        href={link.href}
                        passHref
                        className="text-base text-gray-600 hover:text-gray-900"
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
              Subscribe to our newsletter
            </h3>
            {subscribed && (
              <p className="mt-4 text-base text-gray-600">
                Great stuff! Your now subscribed!
              </p>
            )}
            {!subscribed && (
              <>
                <p className="mt-4 text-base text-gray-600">
                  The latest news, articles, and offers, sent to your inbox
                  monthly.
                </p>
                <form
                  onSubmit={capture}
                  action="https://london.us20.list-manage.com/subscribe/post?u=ea04888e4d30df2311e089ca6&amp;id=778153bad7"
                  method="post"
                  className="mt-4 sm:flex sm:max-w-md"
                >
                  <label htmlFor="emailAddress" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="emailAddress"
                    autoComplete="email"
                    required
                    className="appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:placeholder-gray-400 font-light"
                    placeholder="Enter your email"
                  />
                  <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="w-full bg-brand-600 flex items-center justify-center border border-transparent rounded-md py-2 px-4 text-base outline-none text-white hover:bg-brand-700 focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 font-light"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
        <div
          id="social"
          className="my-8 border-t border-gray-200 pt-8 md:flex md:flex-col lg:flex-row md:text-center md:items-center md:justify-between lg:text-left text-gray-600 space-y-8 md:space-y-0"
        >
          <div className="flex space-x-6 order-3 mt-6 lg:mt-0">
            <a
              onClick={() => trackAction("facebook")}
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/solunaldn"
              className="text-gray-600 hover:text-brand-500"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a
              onClick={() => trackAction("instagram")}
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/soluna_london"
              className="text-gray-600 hover:text-brand-500"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            {/* 2025-03-08: Remove Youtube.
            <a
              onClick={() => trackAction("youtube")}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCHbW58McFsG00hwVB8_JDcg"
              className="text-gray-600 hover:text-brand-500"
            >
              <span className="sr-only">YouTube</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 512 512"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0" />
                <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0" />
              </svg>
            </a>*/}
            {/* <a
              onClick={() => trackAction('messenger')}
              target="_blank"
              rel="noopener noreferrer"
              href="https://m.me/solunalondon"
              className="text-gray-600 hover:text-brand-500"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path d="m12 0c-6.627 0-12 4.974-12 11.111 0 3.498 1.744 6.614 4.469 8.654v4.235l4.088-2.242c1.092.3 2.246.464 3.443.464 6.627 0 12-4.975 12-11.11s-5.373-11.112-12-11.112zm1.191 14.963-3.055-3.26-5.963 3.26 6.559-6.963 3.131 3.259 5.889-3.259z" />
              </svg>
            </a> */}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@soluna_london"
              className="text-gray-600 hover:text-brand-500"
            >
              <span className="sr-only">TikTok</span>
              <svg
                className="h-7 w-7"
                viewBox="0 0 32 32"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title />
                <g data-name="Layer 15" id="Layer_15">
                  <path d="M24,3H8A5,5,0,0,0,3,8V24a5,5,0,0,0,5,5H24a5,5,0,0,0,5-5V8A5,5,0,0,0,24,3Zm3,21a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V8A3,3,0,0,1,8,5H24a3,3,0,0,1,3,3Z" />
                  <path d="M22,12a3,3,0,0,1-3-3,1,1,0,0,0-2,0V19a3,3,0,1,1-3-3,1,1,0,0,0,0-2,5,5,0,1,0,5,5V13a4.92,4.92,0,0,0,3,1,1,1,0,0,0,0-2Z" />
                </g>
              </svg>
            </a>
            {/*<a
              onClick={() => trackAction("whatsapp")}
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.me/message/UI5WMKIS6WJDP1"
              className="text-gray-600 hover:text-brand-500"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="https://www.w3.org/2000/svg"
              >
                <path d="m17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015s-.523.074-.797.359c-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885m8.453-18.302c-2.28-2.204-5.28-3.449-8.475-3.449-6.582 0-11.941 5.334-11.944 11.893 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411" />
              </svg>
            </a>*/}
          </div>
          <div className="flex flex-col">
            <div>
              <span className="order-1 text-base text-gray-600">
                <address className="font-sans not-italic">
                  <a
                    href="https://maps.app.goo.gl/yAAv4FqpjakF7W8F9"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex transition-all duration-100 hover:text-brand-600"
                  >
                    <Location
                      // @ts-ignore
                      width={24}
                      height={18}
                      className="fill-current md:mr-4"
                    />
                    <span className="text-sm md:text-base">
                      <span className="street-address">37a London Road</span>,{" "}
                      <span className="locality">Bromley</span>,{" "}
                      <span className="region">London</span>,{" "}
                      <span className="postal-code">BR1 1DG</span>
                    </span>
                  </a>
                </address>
              </span>
            </div>
          </div>
        </div>
        <div className="md:text-center">
          <p className="text-base text-gray-600 md:w-full order-2">
            &copy;{format(new Date(), "yyyy")} Soluna London Limited (Company
            Number: 15944166). All rights reserved.
          </p>
          <a href="https://withmanaged.com">
            <p className="text-base text-gray-600 order-2 flex items-center mx-auto w-fit mt-4">
              <svg
                width={32}
                height={31}
                viewBox="0 0 44 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="44" height="42.5135" rx="12" fill="#6460FF" />
                <g filter="url(#filter0_d_2033_163)">
                  <path
                    d="M19.8401 22.6143H17.1855L21.9319 16.1765C21.7316 12.5046 18.6987 9.58618 14.976 9.58618C11.2532 9.58618 8.00098 12.7081 8.00098 16.5612V32.9305L19.8401 27.8566V22.6143Z"
                    fill="url(#paint0_linear_2033_163)"
                  />
                  <path
                    opacity="0.8"
                    d="M35.9041 16.5644V32.9337L25.591 28.5147L24.0587 27.8598V22.6174H26.7132L21.9668 16.1797C22.024 15.0702 22.3483 14.0306 22.8601 13.1214C24.0587 11.0104 26.3254 9.58301 28.9227 9.58301C32.7758 9.58301 35.9041 12.7081 35.9041 16.5644Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_2033_163"
                    x="4.00098"
                    y="9.58301"
                    width="35.9033"
                    height="31.3508"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_2033_163"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_2033_163"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_2033_163"
                    x1="14.9665"
                    y1="9.58618"
                    x2="14.9665"
                    y2="32.9305"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="#F3F3F3" />
                  </linearGradient>
                </defs>
              </svg>
              <span className="ml-1">Powered by Managed</span>
            </p>
          </a>
        </div>
      </div>
      <a href="https://solunalondon.com/treatments/laser/" className="sr-only">
        Laser Hair Removal treatments at Soluna London
      </a>
      <a href="https://solunalondon.com/treatments/makeup/" className="sr-only">
        Makeup and micropigmentation treatments at Soluna London
      </a>
    </footer>
  );
}
